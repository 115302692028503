<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/hundun.png">
    <HelloWorld msg="欢迎访问" />
      
      <div style="margin-top: 15px;cursor: pointer;font-size: 30px; color: green;" @click="filterWeb">
        <img style="background-color: green;width: 35px;height: 35px;" src="../assets/point.png">  请点击这里进入网站
      </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      inputValue: '',
      jsonData: null
    }
  },
  mounted() {
    this.loadJsonData();
  },
  methods: {
     async loadJsonData() {
      try {
        const response = await fetch('/web.json');
        if (response.ok) {
          this.jsonData = await response.json();
        } else {
          console.error('Failed to load JSON:', response.status);
        }
      } catch (error) {
        console.error('Error loading JSON:', error);
      }
    },
    filterWeb() {
      let host = window.location.host
      console.log(host)
      const matchedValues = this.findKeyValues(host);
      if (matchedValues.length > 0) {
         window.open(matchedValues[0], '_self')
      }   
    },
   findKeyValues(keyToFind) {
    const result = [];
     for (const key in this.jsonData) {
      if (key === keyToFind) {
        result.push(this.jsonData[key]);
      }
    }

  return result;
}
  }
}
</script>
<style>
  .home{
    display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      flex-direction: column;
  }
</style>